<template>
  <div>
    <h3 class="title">岗位管理</h3>
    <el-divider></el-divider>
    <el-row>
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
    </el-row>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <el-table-column prop="name" label="岗位名称"></el-table-column>
        <el-table-column prop="level" label="等级"></el-table-column>
        <el-table-column prop="order" label="等级"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="pageTags.update"
              @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="pageTags.delete"
              @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
          </template>
        </el-table-column>
      </el-table> 
    </div>

    <!-- el-dialog manage-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="岗位名称" prop="name">
            <el-input v-model="ruleForm.name" placeholder="岗位名称"></el-input>
          </el-form-item>
          <el-form-item label="等级" prop="level">
            <el-input type="number" v-model="ruleForm.level" placeholder="等级"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="order">
            <el-input type="number" v-model="ruleForm.order" placeholder="排序"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
        <el-button type="primary" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        ruleForm: {
          name:"",
          level:"",
          order:""
        },
        editIndex: -1,
        dialogTitle:"岗位管理",
        addDialog:true,
        editId:"",
        pageTags:{}
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/posts").then(response => {
          if (response.status == 200) {
            self.tableData = response.data.data;
          } else{
            console.log(response.message);
          }
          self.loading = false;
          self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
        }).catch(err => {
          console.log('××××××××××××××');
          self.loading = false;
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش ";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        console.log(row);
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش ";
        self.editId = row.id;
        self.ruleForm.name = row.name;
        self.ruleForm.level = row.level;
        self.ruleForm.order = row.order;
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("确认ئۆچۈرۈش ？", "提示", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/posts/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش مەشغۇلات完成",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "بىكار قىلىشئۆچۈرۈش "
          });
        });
      },
      submitForm(formName) {
        var data = {
          name:self.ruleForm.name,
          level:self.ruleForm.level,
          order:self.ruleForm.order
        }
        // قوشۇش 
        if(self.addDialog){
          self.$post('admin/posts',data).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
          });
        }else{ //修改
          self.$put('admin/posts/'+self.editId,data).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm.name = "";
        self.ruleForm.level = "";
        self.ruleForm.order = "";
      },
      closeDialog(formName) {
        self.$refs[formName].resetFields();
        self.resetForm("ruleForm");
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.$refs.ruleForm.resetFields();
        self.resetForm("ruleForm");
      }
    }
  };
</script>

